/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbo:load', function () {
  // Removing and Adding fields for arrays
  // Source: http://railscasts.com/episodes/370-ransack
  $('form').on('click', '.remove_fields', function (event) {
    $(this).closest('.field').remove()
    return event.preventDefault()
  })

  $('form').on('click', '.add_fields', function (event) {
    const time = new Date().getTime()
    const type = $(this).data('type')

    const $fields = $($(this).data('fields'))
    const $input = $fields.find('input')
    $input.attr('id', $input.attr('id') + String(time))

    $(this).before($fields)
    $(this)
      .prev()
      .trigger({ type: 'nested:fieldAdded', field: $(this).prev() })
      .trigger({ type: `nested:fieldAdded:${type}`, field: $(this).prev() })
    return event.preventDefault()
  })

  // Category Manager
  $('#publication_categories').tagsManager({
    prefilled: $('#publication_categories').data('load'),
    typeahead: true,
    typeaheadSource(query, process) {
      return jQuery.ajax({
        cache: false,
        type: 'GET',
        contentType: 'application/json',
        dataType: 'json',
        url: '/categories/autocomplete.json',
        data: $.param({ q: query }),
        success(data) {
          return process(data)
        },
      })
    },
    hiddenTagListName: 'publication[category_tokens]',
    preventSubmitOnEnter: true,
    blinkBGColor_1: '#FFFF9C',
    blinkBGColor_2: '#CDE69C',
    backspace: [],
  })

  // Nested form support for tables
  // Source: https://github.com/ryanb/nested_form/issues/225
  // and https://github.com/ryanb/nested_form/wiki/How-To:-Render-nested-fields-inside-a-table
  window.NestedFormEvents.prototype.insertFields = function (content, assoc, link) {
    if ($(link).hasClass('insert_in_table')) {
      return $(link).closest('table').find('tbody').append(content)
    } else {
      return $(content).insertBefore(link)
    }
  }

  // Change publishable type partial
  $(
    '#publications-new_single, #publications-create, #publications-edit, #publications-update, #publications-clone',
  )
    .find('#publication_publishable_type')
    .on('change', function () {
      const form = $(this).closest('form')
      const form_outer = form.find('.type-form-outer')
      form.hide()
      return $.ajax({
        url: '/publications/form',
        data: form.serialize(),
        dataType: 'html',
        type: 'PUT',
      })
        .done((html) => form_outer.html($(html).find('.type-form-inner')))
        .always(() => form.show())
    })

  $('#publications-index #paginator').on('ajax:beforeSend', 'a[data-remote]', function () {
    return window.history.pushState(null, '', this.href)
  })

  $('#publications-index label.checkbox').on('change', 'input', function () {
    return $(this).closest('form').submit()
  })

  // Check all checkboxes on publications#fetch
  $('#publications-fetch, #publications-update_fetch')
    .find('#select_all')
    .on('click', function () {
      $(this)
        .closest('form')
        .find('[name="apply_fields[]"]')
        .prop('checked', true)
        .trigger('change')
      $(this).hide()
      return $(this).closest('form').find('#unselect_all').show()
    })

  $('#publications-fetch, #publications-update_fetch')
    .find('#unselect_all')
    .on('click', function () {
      $(this)
        .closest('form')
        .find('[name="apply_fields[]"]')
        .prop('checked', false)
        .trigger('change')
      $(this).hide()
      return $(this).closest('form').find('#select_all').show()
    })

  $('#publications-fetch, #publications-update_fetch')
    .find('form#publication_form input[name="apply_fields[]"]')
    .on('change', function () {
      const $tr = $(this).closest('tr')
      if ($(this).prop('checked')) {
        return $tr.addClass('info').find('input[type="hidden"]').removeProp('disabled')
      } else {
        return $tr.removeClass('info').find('input[type="hidden"]').prop('disabled', 'disabled')
      }
    })

  return $('#publications-show, .publication-listing')
    .find('.star-container a[data-remote]')
    .on('ajax:success', function (event) {
      const data = event.detail[0]
      const container = $(this).closest('.star-container')
      container.find('.count').text(data.count)
      return container.toggleClass('on')
    })
})
