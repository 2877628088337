import { showAlert } from '../alertNotifications'

class AutomaticCreateForm {
  constructor($form, configs = {}) {
    this.$form = $form
    this.$submitBtn = $form.find("input[type='submit']")
    this.configs = configs
  }

  initialize() {
    this.initSubmitCallback()
    this.initTypeSelector()
  }

  initTypeSelector() {
    var self = this

    $(document).on('change', '#type-selector', function () {
      const type = $(this).val()
      ;['isbn', 'doi', 'standard', 'pmid'].forEach((t) => {
        $(`#${t}-field`).hide().prop('disabled', true).removeAttr('required')
      })
      $(`#${type}-field`).show().prop('disabled', false).attr('required', 'required')
    })
  }

  initSubmitCallback() {
    var self = this

    self.$form.submit(function (e) {
      e.preventDefault()
      e.stopPropagation()

      self.postPublication()

      return true
    })
  }

  postPublication() {
    var self = this

    self.$submitBtn.button('loading')

    $.ajax({
      method: 'POST',
      url: self.$form.attr('action'),
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      dataType: 'json',
      data: self.$form.serialize(),
    })
      .done(function (data) {
        showAlert(data.title + ' successfully created', 'success', 5000)
        self.$form[0].reset()
        self.prependPublication(data)
      })
      .fail(function (data) {
        console.error(data)
        showAlert('Error: ' + data.responseJSON.error.message, 'danger')
      })
      .always(function () {
        self.$submitBtn.button('reset')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      })
  }

  prependPublication(publication) {
    $('#new-publications .media-list').prepend(publication.media_item_html)
  }
}

$(document).on('turbo:load', function () {
  var form = new AutomaticCreateForm($('#automatic-publication-form'))
  form.initialize()
})
