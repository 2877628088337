import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import Dashboard from '@uppy/dashboard'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { showAlert } from '../alertNotifications'

class PublicationBulkCreateForm {
  constructor(fileInput, configs = {}) {
    this.fileInput = fileInput
    this.configs = configs
    this.$form = $(this.fileInput).siblings('#uploader-publications-form')
    this.successfulUploads = []
  }

  initialize() {
    this.initUppyDashboard()
    this.initUploadCallback()
    this.initFormSubmit()
  }

  initUppyDashboard() {
    var self = this

    this.uppy = new Uppy({
      id: self.fileInput.id,
      autoProceed: true,
      restrictions: {
        maxFileSize: self.configs.maxFileSize,
        allowedFileTypes: self.configs.allowedFileTypes,
      },
    })
      .use(Dashboard, {
        target: self.fileInput,
        inline: true,
        note: self.fileInput.dataset.description,
        width: 2400,
        showProgressDetails: true,
        height: 430,
        locale: {
          strings: {
            dropPaste: self.fileInput.dataset.title + ' %{browse}',
            browse: self.fileInput.dataset.browse,
          },
        },
        proudlyDisplayPoweredByUppy: false,
      })
      .use(AwsS3, {
        getUploadParameters(file) {
          const filename = encodeURIComponent(file.meta.name)
          const type = encodeURIComponent(file.meta.type)
          return fetch(`/publications/files/new/presign?filename=${filename}&type=${type}`).then(
            (response) => response.json(),
          )
        },
      })
  }

  initFormSubmit() {
    var self = this

    self.$form.submit(function (e) {
      e.preventDefault()
      e.stopPropagation()
      var publicationsParams = self.preparePublicationParams(
        self.successfulUploads,
        self.$form.serializeFormJSON(),
      )

      publicationsParams.forEach((params) => {
        self.postPublication(params)
      })

      return publicationsParams
    })
  }

  initUploadCallback() {
    var self = this

    self.uppy.on('complete', (result) => {
      showAlert('Successfully uploaded', 'success', 5000)
      self.$form.slideDown()

      self.successfulUploads = result.successful
      return true
    })
  }

  preparePublicationParams(uploadedFiles, formParams) {
    var self = this
    return uploadedFiles.map((uploadedFile) =>
      Object.assign({
        publication: {
          title: uploadedFile.meta.name,
          import_state: 'ready',
          publishable_type: formParams['publication[publishable_type]'],
          language: formParams['publication[language]'],
          category_tokens: formParams['publication[category_tokens]'],
          assets_attributes: {
            0: { file: self.uploadedFileData(uploadedFile) },
          },
        },
      }),
    )
  }

  postPublication(params) {
    var self = this
    $.ajax({
      method: 'POST',
      url: self.$form.attr('action'),
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify(Object.assign({}, params)),
    })
      .done(function (data) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        showAlert(data.title + ' successfully created', 'success', 5000)
        self.reset()
        var $publicationDom = self.prependPublication(data)
        self.initThumbnailChecker(data, $publicationDom)
      })
      .fail(function (data) {
        console.error(data)
        showAlert('Error: ' + JSON.stringify(data), 'danger')
      })
  }

  prependPublication(publication) {
    var el = $(publication.media_item_html)

    $('#new-publications .media-list').prepend(el)
    return el
  }

  uploadedFileData(file) {
    return JSON.stringify({
      id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    })
  }

  reset() {
    this.uppy.reset()
    this.$form[0].reset()
    this.$form.slideUp()
  }

  initThumbnailChecker(publication, $publicationDom) {
    var self = this

    let timerId = setInterval(function () {
      $.ajax({
        method: 'GET',
        url: self.$form.attr('action') + '/' + publication.id,
        contentType: 'application/json',
        dataType: 'json',
      })
        .done(function (data) {
          if (data.cover_image.present) {
            $publicationDom.find('.media-object').attr('src', data.cover_image.url)
            clearInterval(timerId)
          }
        })
        .fail(function (data) {
          console.error(data)
        })
    }, 5000)
  }
}

$(document).on('turbo:load', function () {
  document
    .querySelectorAll('#uploader-publications #uploader-publications-dropzone')
    .forEach(function (fileInput) {
      var form = new PublicationBulkCreateForm(fileInput, {
        maxFileSize: 200 * 1024 * 1024,
        allowedFileTypes: ['application/pdf', '.pdf'],
      })

      form.initialize()
    })
})
