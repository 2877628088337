class AggregationSearch {
  constructor(input, args = {}) {
    this.$input = input
    this.type = input.attr('name')
    this.url = '/api/v2/publication-aggregations/' + this.type
    this.args = args
    this.type_query = args.type_query || ''
    this.query = args.query || ''
    this.filters = args.filters || {}
  }

  fetchData(type_query, callback) {
    let self = this

    let data = Object.assign(self.filters, {
      type_query: type_query,
      query: self.query,
    })

    $.ajax({
      method: 'GET',
      url: self.url,
      contentType: 'application/json',
      dataType: 'json',
      data: data,
    })
      .done(function (data) {
        callback(self.parseResponse(data))
      })
      .fail(function (data) {
        console.error(data)
      })
  }

  parseResponse(response) {
    let self = this

    if (self.type === 'year') {
      return response.map(function (item) {
        return new Date(item.name).getFullYear().toString()
      })
    } else {
      return response.map((item) => item.name)
    }
  }

  init() {
    let self = this

    self.$input.typeahead({
      source(query, process) {
        return self.fetchData(query, process)
      },
      minLength: 1,
      items: 25,
    })
  }
}

$(document).on('turbo:load', function () {
  $('.filter-autocomplete').each(function () {
    new AggregationSearch($(this)).init()
  })
})
